import React from "react";
import Link from "next/link";
import styles from "./styles.module.css";
import {
  LanguageUid,
  LanguageValueType,
  MenuSettings,
  Language,
} from "../../../../libs/enums";
import UseTranslation from "next-translate/useTranslation";
import { CDN_URL } from "../../../../libs/generalSettings";
import { GetLanguageResource, IsNullOrEmpty } from "../../../../libs/utils";

const Special = ({ menus }) => {
  const { t, lang } = UseTranslation("GetLanguageResourceResponse");
  return (
    <nav className={styles.special}>
      <div className={styles._special}>
        <ul className={styles.left}>
          {menus &&
            menus
              .filter(
                (f) =>
                  f.slug == MenuSettings.SLUG.TOP_MENU &&
                  !IsNullOrEmpty(f.languageResources)
              )
              .map((specialMenu, index) => (
                <li key={index}>
                  <Link
                          href={`${CDN_URL}Ptt_Hizmet_Katalogu_(1)_compressed.pdf`}
                    rel="noreferer"
                  >
                    <a target="_blank">
                      {
                        GetLanguageResource(
                          specialMenu.languageResources,
                          LanguageValueType.MENU
                        ).value
                      }
                    </a>
                  </Link>
                </li>
              ))}
        </ul>

        <ul className={styles.right}>
          <li>
            <Link href={"tel:4441788"}>
              <a>
                <i className="fa-solid fa-phone"></i>
                <span>444 1 788</span>
              </a>
            </Link>
          </li>
          {lang.toUpperCase() === Language.LanguageText.TR.toUpperCase() ? (
            <li>
              <Link href={"/iletisim-bilgileri"}>
                <a>
                  <i className="fa-solid fa-paper-plane"></i>
                  <span>{t(LanguageUid.CONTACT_US)}</span>
                </a>
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>

        <ul className={styles.social}>
          <li>
            <Link
              href={"https://www.facebook.com/Ptt.Kurumsal"}
              rel="noreferer"
            >
              <a target="_blank" title="Facebook">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </Link>
          </li>
          <li>
            <Link
              href={"https://www.instagram.com/pttkurumsal/"}
              rel="noreferer"
            >
              <a target="_span" title="Instagram">
                <i className="fa-brands fa-instagram"></i>
              </a>
            </Link>
          </li>
          <li>
            <Link href={"https://twitter.com/PTTKurumsal"} rel="noreferer">
              <a target="_blank" title="Twitter">
                <i className="fa-brands fa-x-twitter"></i>
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Special;
