import { applyMiddleware } from "@reduxjs/toolkit";
import { createStore } from "redux";
import thunk from "redux-thunk";
import RootReducer from "../redux/reducers/combine_reducer";

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");

    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
};

const Store = createStore(RootReducer, bindMiddleware([thunk]));

export default Store;
