import Head from "next/head";
import { useEffect } from "react";

function ChatSystem() {
  const startWidget = async (data) => {
    if(typeof AloChat !== 'undefined'){
      AloChat.init({
      uid: "ahRzfm11c3RlcmktaGl6bWV0bGVyaXIYCxILQ2hhdFdpZGdldHMYgICk1cbTrwgMogEScHR0ZW0uYWxvLXRlY2guY29t",
      hostname: "pttem.alo-tech.com",
      ws_server: "chatserver.alo-tech.com",
      lang: "tr",
      customer_history:
        data && data.customer_history ? data.customer_history : "",
      customer_path_param:
        data && data.customer_path_param ? data.customer_path_param : "",
      optional_email: "",
      optional_phone: "required",
      title: "Canlı Destek",
      title_background_color: "#FFFFFF",
      title_text_color: "#00A7CF",
      subtitle: "Canlı Destek",
      height: "500px",
      width: "350px",
      client_name: data && data.client_name ? data.client_name : "",
      client_email: data && data.client_email ? data.client_email : "",
      phone_number: data && data.phone_number ? data.phone_number : "",
      user_data: data && data.user_data ? data.user_data : "",
      queue: [
        {
          name: "Whatsapp Chat",
          key: "ahRzfm11c3RlcmktaGl6bWV0bGVyaXISCxIFUXVldWUYgIC47ozj_ggMogEScHR0ZW0uYWxvLXRlY2guY29t",
        },
      ],
      legal_requirements: 0,
      widget_version: "version1",
      chat_widget_icon:
        "chat/chat_icons/3af51a49-058b-4e67-9af9-1575813efeeb.png",
      hide_new_chat_form: "False",
      customer_files: "False",
      send_emoji: "False",
      own_files: "False",
    });
  };
  }

  useEffect(() => {
    startWidget();
  }, []);

  return (
    <>
      <Head>
        <script
          type="text/javascript"
          src="//pttem.alo-tech.com/chat/alochat.js?widget_key=ahRzfm11c3RlcmktaGl6bWV0bGVyaXIYCxILQ2hhdFdpZGdldHMYgICk1cbTrwgMogEScHR0ZW0uYWxvLXRlY2guY29t"
        ></script>
      </Head>
    </>
  );
}

export default ChatSystem;
