
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import "../assets/styles/pttglobal.css";
import "../assets/styles/content-styles.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/brands.min.css";
import "@fortawesome/fontawesome-free/css/solid.min.css";
import React, { useEffect as UseEffect, useState as UseState } from "react";
import { useRouter as UseRouter } from "next/router";
import Progress from "../components/progress/index";
import UseTranslation from "next-translate/useTranslation";
import { CloseModelById } from "../libs/utils";
import { Language } from "../libs/enums";
import { API_BASE_URL, STORAGE_LANGUAGE_ID } from "../libs/generalSettings";
import Special from "../components/layout/header/special";
import Menu from "../components/layout/header/menu";
import Footer from "../components/layout/footer";
import Head from "next/head";
import { connect, Provider } from "react-redux";
import Store from "../redux/store";
import { setCookie as SetCookie } from "cookies-next";
import Customservices from "../components/custom_services";
import Transactions from "../components/layout/header/transactions";
import ChatSystem from "../components/chat_system";
import App from "next/app";
import ErrorBoundary from "../components/error_boundary/ErrorBoundary";
import Script from "next/script";

const MyApp = ({ Component, pageProps }) => {
  const { t, lang } = UseTranslation("GetLanguageResourceResponse");
  const router = UseRouter();
  const [progress, setProgress] = UseState(<></>);

  UseEffect(() => {
    switch (lang) {
      case "tr":
        SetCookie(STORAGE_LANGUAGE_ID, Language.LanguageId.TR);
        break;
      case "en":
        SetCookie(STORAGE_LANGUAGE_ID, Language.LanguageId.EN);
        break;
      default:
        SetCookie(STORAGE_LANGUAGE_ID, Language.LanguageId.TR);
        break;
    }
  }, [lang]);

  UseEffect(() => {
    router.events.on("routeChangeStart", () => {
      setProgress(<Progress />);
      document.body.style.overflow = "hidden";
    });

    return () => {
      setProgress(<></>);
      document.body.style.overflow = "auto";

      CloseModelById("megaMenu");
    };
  }, [router]);

  return (
    <>
      <Provider store={Store}>
        <Head>
          <meta name="description" content="..." />
          <meta
            name="google-site-verification"
            content="bVx0mhHpHVWbn0cO6FDyC09w8-cFYjRd0m_x6BFB__A"
          />
          <meta name="google" content="notranslate" />

          <link rel="icon" href="/favicon.ico" />
        </Head>

        {progress}

        <header>
          <Special menus={pageProps.menus} />
          <Menu menus={pageProps.menus} />
          <Transactions menus={pageProps.menus}></Transactions>
        </header>

        <Script
          strategy="afterInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=G-SFQ172Y7CH"
        />

        <Script id="google-analytics" strategy="afterInteractive">
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-SFQ172Y7CH');
          `}
        </Script>

        <Customservices customServiceMenus={pageProps.menus}></Customservices>

        <main>
          <ErrorBoundary key={router.asPath}>
            <Component {...pageProps} />
          </ErrorBoundary>
        </main>

        <ChatSystem />

        <Footer menus={pageProps.menus}></Footer>
      </Provider>
    </>
  );
};

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const menuResponse = await fetch(
    `${API_BASE_URL}/Menu?IsAdminPage=false&LanguageId=${
      Language.LanguageId[appContext?.ctx?.locale?.toUpperCase()] ?? 1
    }`
  );

  const menus = await menuResponse.json();

  appProps.pageProps.menus = menus;

  return { ...appProps };
};

const mapStateToProps = (state) => {
  return state;
};

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  