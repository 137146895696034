import {
  GetAction_SearchModalIsActive,
  UpdateAction_SearchModalIsActive,
} from "../actions/searchModal";

const Get_SearchModalIsActive = (isActive) => {
  return (dispatch) => {
    dispatch(GetAction_SearchModalIsActive(isActive));
  };
};

const Update_SearchModalIsActive = (isActive) => {
  return (dispatch) => {
    dispatch(UpdateAction_SearchModalIsActive(isActive));
  };
};

export { Get_SearchModalIsActive, Update_SearchModalIsActive };
