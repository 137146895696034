import React, {
  useEffect as UseEffect,
  useState as UseState,
  useRef as UseRef,
} from "react";
import { connect } from "react-redux";
import styles from "./styles.module.css";
import { Update_SearchModalIsActive } from "../../redux/dispatch/searchModal";
import { KeyCode, Language, LanguageUid, SourceTypes } from "../../libs/enums";
import { API_BASE_URL } from "../../libs/generalSettings";
import UseTranslation from "next-translate/useTranslation";
import Link from "next/link";
import parse from "html-react-parser";
import NewsNoData from "../no_data/news_no_data";
import { IsNullOrEmpty } from "../../libs/utils";

function SearchModal({ searchModal, onUpdateSearchModalIsActive }) {
  const { t, lang } = UseTranslation("GetLanguageResourceResponse");
  const [data, setData] = UseState([]);
  const [searchText, setSearchText] = UseState({ searchText: "" });
  const [hasSearch, setHasSearch] = UseState(false);
  const [progress, setProgress] = UseState(false);
  const [url, setUrl] = UseState("");
  const [selectedPage, setSelectedPage] = UseState(1);

  const searchBody = UseRef();

  let numbers = [];

  const onInput = (event) => {
    setSearchText({ ...searchText, [event.target.name]: event.target.value });
  };

  const GetSearchList = async (searchText = "", pageNumber = 1) => {
    const request = await fetch(
      `${API_BASE_URL}/LanguageResource/getBySearchText?SearchText=${searchText}&PageNumber=${pageNumber}&LanguageId=${
        Language.LanguageId[lang.toUpperCase()]
      }`
    );

    return await request.json();
  };

  const GetSourceType = (sourceType = 0) => {
    switch (sourceType) {
      case SourceTypes.Announcement:
        return "duyurular";
      case SourceTypes.News:
        return "haberler";
      case SourceTypes.Page:
        return "";
      case SourceTypes.Gallery:
        return "galeri";
      default:
        break;
    }
  };

  const GetSourceType_Tag = (sourceType = 0) => {
    switch (sourceType) {
      case SourceTypes.Announcement:
        return {
          text: t(LanguageUid.ANNOUNCEMENT_TITLE),
          color: "var(--pttLinkColor)",
        };
      case SourceTypes.News:
        return {
          text: t(LanguageUid.NEWS_TITLE),
          color: "var(--pttYellowColor)",
        };
      case SourceTypes.Menu:
        return {
          text: t(LanguageUid.MENU_TITLE),
          color: "",
        };
      case SourceTypes.Page: {
        return {
          text: t(LanguageUid.PAGE_TITLE),
          color: "var(--pttGreenColor)",
        };
      }
      default:
        break;
    }
  };

  const TextFontColor = (text = "") => {
    let start = text
      .toLocaleLowerCase()
      .indexOf(searchText.searchText.toLocaleLowerCase());
    let end = parseInt(start) + searchText.searchText.length;

    let newText = text.toLocaleUpperCase().replace(
      searchText.searchText.toLocaleUpperCase(),
      `<span
        style='
          background-color: var(--pttYellowColor); 
          padding: 0 0.5rem;
          border-radius: .3rem;
          color: var(--pttLinkColor);
          font-weight: var(--fontWeight)
        '
        >${text.toLocaleUpperCase().substring(start, end)}</span>`
    );

    return newText;
  };

  const Paging = (totalCount) => {
    const pageNumbers = Math.ceil(totalCount / 10);

    let pageNumbersOfLeft = selectedPage < 2 ? 1 : selectedPage - 1;
    let pageNumbersOfRight =
      selectedPage < 2 ? selectedPage + 2 : selectedPage + 1;

    if (pageNumbersOfRight >= pageNumbers) {
      pageNumbersOfLeft = pageNumbers - 2;

      if (pageNumbersOfLeft <= 0) {
        pageNumbersOfLeft = 1;
      }

      pageNumbersOfRight = pageNumbers;
    }

    for (let i = pageNumbersOfLeft; i <= pageNumbersOfRight; i++) {
      numbers.push(
        <li
          key={i}
          className={selectedPage == i ? styles.isActive : null}
          onClick={() => {
            if (selectedPage == i) return;

            GetSearchList(searchText.searchText, i).then((result) => {
              setData(result);
              setProgress(false);
              setSelectedPage(i);
            });
          }}
        >
          {i}
        </li>
      );
    }

    if (!IsNullOrEmpty(searchBody.current)) {
      searchBody.current.scrollTop = 0;
    }

    return numbers;
  };

  UseEffect(() => {
    setUrl(`${window.location.protocol}//${window.location.host}`);
  }, []);

  return (
    <section className={styles.searchContainer}>
      <i
        className="fa-solid fa-xmark"
        onClick={() => {
          onUpdateSearchModalIsActive(!searchModal);
        }}
      ></i>

      <div className={styles.searchField}>
        <div className={styles.input}>
          {!progress ? (
            <i className="fa-solid fa-magnifying-glass"></i>
          ) : (
            <i className={`fa-solid fa-spinner ${styles.progress}`}></i>
          )}

          <input
            type={"text"}
            placeholder={t(LanguageUid.SEARCH_IN_SITE)}
            name="searchText"
            autoComplete={"off"}
            autoFocus={"on"}
            onKeyUp={(event) => {
              event.preventDefault();

              if (event.code === KeyCode.Enter) {
                setProgress(true);

                GetSearchList(searchText.searchText).then((result) => {
                  setData(result);
                  setProgress(false);
                  setHasSearch(true);
                  setSelectedPage(1);
                });
              }

              onInput(event);
            }}
          />

          <button
            onClick={() => {
              setProgress(true);

              GetSearchList(searchText.searchText).then((result) => {
                setData(result);
                setProgress(false);
                setHasSearch(true);
                setSelectedPage(1);
              });
            }}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>

        {data && data.totalCount > 0 ? (
          <>
            <div className={styles.searchBody} ref={searchBody}>
              <ul>
                {data.languageResourcesBySearchValues.map((item, i) => (
                  <li key={i}>
                    <span>
                      <span
                        style={{
                          display:
                            IsNullOrEmpty(
                              data.navigate.filter((f) => f[item.sourceUid])
                            ) && item.sourceType == SourceTypes.Page
                              ? "none"
                              : "inline-block",
                          background: GetSourceType_Tag(item.sourceType).color,
                        }}
                      >
                        {item.sourceType == SourceTypes.Page
                          ? data.navigate
                              .filter((f) => f[item.sourceUid])
                              .map((i) => (
                                <>
                                  {i[item.sourceUid].reverse().map((_i) => (
                                    <>
                                      <span>{_i}</span>
                                      <i className="fa-solid fa-right-long"></i>
                                    </>
                                  ))}
                                </>
                              ))
                          : GetSourceType_Tag(item.sourceType).text}
                      </span>

                      <Link
                        href={`${GetSourceType(item.sourceType)}/${item.slug}`}
                      >
                        <a
                          onClick={() => {
                            onUpdateSearchModalIsActive(!searchModal);
                          }}
                        >
                          {parse(TextFontColor(item.value))}
                        </a>
                      </Link>
                    </span>

                    <Link
                      href={`${GetSourceType(item.sourceType)}/${item.slug}`}
                    >
                      <a
                        onClick={() => {
                          onUpdateSearchModalIsActive(!searchModal);
                        }}
                      >
                        {url}
                        {[SourceTypes.Announcement, SourceTypes.News].includes(
                          item.sourceType
                        ) && "/"}
                        {GetSourceType(item.sourceType)}/{item.slug}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>

              {data.totalCount > 10 && (
                <ul className={styles.paging}>
                  <li
                    key={98}
                    onClick={() => {
                      GetSearchList(searchText.searchText, 1).then((result) => {
                        setData(result);
                        setProgress(false);
                        setSelectedPage(1);
                      });
                    }}
                  >
                    <i className="fa-solid fa-angles-left"></i>
                  </li>
                  {Paging(data.totalCount)}
                  <li
                    key={99}
                    onClick={() => {
                      GetSearchList(
                        searchText.searchText,
                        Math.ceil(data.totalCount / 10)
                      ).then((result) => {
                        setData(result);
                        setProgress(false);
                        setSelectedPage(Math.ceil(data.totalCount / 10));
                      });
                    }}
                  >
                    <i className="fa-solid fa-angles-right"></i>
                  </li>

                  <li key={100} className={styles.selectedPage}>
                    {selectedPage} / {Math.ceil(data.totalCount / 10)} |{" "}
                    {t(LanguageUid.SEARCH_TOTAL_RECORD)} : {data.totalCount}
                  </li>
                </ul>
              )}
            </div>
          </>
        ) : (
          hasSearch && <NewsNoData />
        )}
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  onUpdateSearchModalIsActive: Update_SearchModalIsActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchModal);
