import { ActionTypes } from "../../libs/enums";

const GetAction_SearchModalIsActive = (isActive = false) => {
  return {
    type: ActionTypes.GET_SEARCH_MODAL_IS_ACTIVE,
    payload: isActive,
  };
};

const UpdateAction_SearchModalIsActive = (isActive) => {
  return {
    type: ActionTypes.UPDATE_SEARCH_MODAL_IS_ACTIVE,
    payload: isActive,
  };
};

export { GetAction_SearchModalIsActive, UpdateAction_SearchModalIsActive };
