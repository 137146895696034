import { ActionTypes } from "../../libs/enums";

const Reducer_SearchModal = (state = false, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_SEARCH_MODAL_IS_ACTIVE:
      return payload;
    case ActionTypes.UPDATE_SEARCH_MODAL_IS_ACTIVE:
      return payload;
    default:
      return state;
  }
};

export default Reducer_SearchModal;
