import { ActionTypes } from "../../libs/enums";

const Reducer_GetAnnouncementType = (state = 1, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_ANNOUNCEMENT_TYPE:
      return payload;
    default:
      return state;
  }
};

export default Reducer_GetAnnouncementType;
