import {
  GetAction_CustomServiceIsActive,
  UpdateAction_CustomServiceIsActive,
} from "../actions/getCustomServiceIsActive";

const Get_CustomServiceIsActive = () => {
  return (dispatch) => {
    dispatch(GetAction_CustomServiceIsActive());
  };
};

const Update_CustomServiceIsActive = (isActive) => {
  return (dispatch) => {
    dispatch(UpdateAction_CustomServiceIsActive(isActive));
  };
};

export { Get_CustomServiceIsActive, Update_CustomServiceIsActive };
