import { ActionTypes } from "../../libs/enums";

const Reducer_GetCustomServiceIsActive = (state = true, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_CUSTOM_SERVICE_IS_ACTIVE:
      return payload;
    case ActionTypes.UPDATE_CUSTOM_SERVICE_IS_ACTIVE:
      return payload;
    default:
      return state;
  }
};

export default Reducer_GetCustomServiceIsActive;
