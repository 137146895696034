import React from "react";
import CustomError from "../notfound";

const initialState = { hasError: false };

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = initialState;
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    // console.log({ error, errorInfo });
  }

  onReset() {
    this.props.onReset?.();
    this.setState(initialState);
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <CustomError></CustomError>;
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
