import { DateType, Months } from "./enums";

/**
 * İstenilen modal'ı açar.
 * @param {*} element
 */
export const OpenModelById = (elementId) => {
  document.getElementById(elementId).classList.toggle("fade-in-out");
  document.body.classList.toggle("overflow-hidden");
};

export const CloseModelById = (elementId) => {
  var element = document.getElementById(elementId);

  if (element) {
    element.classList.remove("fade-in-out");
  }

  document.body.classList.remove("overflow-hidden");
};

export const GetStorage = (key) => {
  return localStorage.getItem(key);
};

export const ConvertSlug = (str) => {
  var result = str
    .toString()
    .toLowerCase()
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, "-")
    .replace(/[&\/\\#,+()$~%.'":*?<>{}=^!]/g, "")
    .replace(/[ı]/g, "i")
    .replace(/[ğ]/g, "g")
    .replace(/[ü]/g, "u")
    .replace(/[ş]/g, "s")
    .replace(/[ö]/g, "o")
    .replace(/[ç]/g, "c");

  return result;
};

export const ConvertToDateByType = (type, date) => {
  const _date = new Date(date);

  switch (type) {
    case DateType.DAY:
      return _date.getDate();
    case DateType.MONTH:
      return Months[_date.getMonth() + 1];
    case DateType.YEAR:
      return _date.getFullYear();
    case DateType.HOURS:
      return `| ${_date.getHours()}:${_date.getMinutes()}`;
    default:
      break;
  }
};

export const BetweenDateControl = (date1, date2) => {

  var currentDate = new Date();//.toJSON().slice(0, 10);
  var from = new Date(date1);
  var to = new Date(date2);
  var check = new Date(currentDate);
  return check > from && check < to;
};
export function suankiTarihArasindaMi(startDate, endDate) {
  const suankiTarih = new Date();
  const baslangicTarihi = new Date(startDate);
  const bitisTarihi = new Date(endDate);

  return baslangicTarihi <= suankiTarih && suankiTarih <= bitisTarihi;
}

export const setCookiePool = (name, json) => {
  let cookieValue = "";
  let expire = "";
  let period = "";
  cookieValue = name + "=" + JSON.stringify(json) + ";";
  cookieValue += "path=/ ;";
  period = 7;
  expire = new Date();
  expire.setTime(expire.getTime() + 1000 * 3600 * 24 * period);
  expire.toUTCString();
  cookieValue += "expires=" + expire + ";";

  //Set cookie
  document.cookie = cookieValue;
};

/**
 * [0] -> Genel Gösterilecek kayıt sayısı
 * [1] -> Haberler için gösterilecek kayıt sayısı
 * [2] -> İlk gösterim sayısı
 * @returns
 */
export const GetNumberOfRecordsByScreenSize = () => {
  if (window.innerWidth > 1280) {
    return [8, 21, 5];
  } else if (window.innerWidth >= 800 && window.innerWidth <= 1280) {
    return [8, 33, 3];
  } else if (window.innerWidth <= 790) {
    return [8, 100, 1];
  } else {
    return 99999;
  }
};

export const IsNullOrEmpty = (value) => {
  return (
    value === null || value === undefined || value.length === 0 || value === ""
  );
};

export const GetLanguageResource = (arr, type) => {
  return !IsNullOrEmpty(arr) && arr.find((x) => x.valueType === type);
};

export const ValidExtensions = {
  ValidImageExtensions: [
    "jpg",
    "jpeg",
    "jfif",
    "pjpeg",
    "pjp",
    "png",
    "svg",
    "webp",
    "gif",
    "avif",
    "apng",
    "bmp",
  ],
  ValidFileExtensions: ["pdf", "doc", "docx", "xlsx", "xls"],
};

export const ValidMimeTypes = {
  ValidFileMimeTypes: [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //.docx
    "application/msword", //.doc
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //.xlsx
    "application/vnd.ms-excel", //.xls
  ],
  ValidImageMimeTypes: [
    "image/jpg",
    "image/jpeg",
    "image/jfif",
    "image/pjpeg",
    "image/pjp",
    "image/png",
    "image/svg",
    "image/webp",
    "image/gif",
    "image/avif",
    "image/apng",
    "image/bmp",
  ],
};

export const GetMimeType = (fileName) => {
  let mimeType = "";
  var type = fileName.split(".");

  if (type) {
    return "data:image/jpg;base64, ";
  }

  var x = type[type.length - 1];
  const fileExtension = x;

  if (fileExtension && fileExtension !== "") {
    if (ValidExtensions.ValidImageExtensions.includes(fileExtension)) {
      mimeType = "data:image/" + fileExtension + ";base64,";
    } else {
      switch (fileExtension) {
        case "docx":
          mimeType =
            "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
          break;
        case "doc":
          mimeType = "data:application/msword;base64,";
          break;
        case "xlsx":
          mimeType =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
          break;
        case "xls":
          mimeType = "data:application/vnd.ms-excel;base64,";
          break;
        case "pdf":
          mimeType = "data:application/pdf;base64,";
          break;
        case "mp4":
          mimeType = "data:application/octet-stream;base64,";
      }
    }
  }
  return mimeType;
};

export const DownloadFile = (fileName, base64String) => {
  let mimeType = GetMimeType(fileName);
  let a = document.createElement("a");
  a.href = base64String;
  a.download = fileName;
  a.click();
};

export const Base64ToMBSize = (base64) => {
  base64 = base64.substring(base64.indexOf(",") + 1);
  return base64 ? ((base64.length * 6) / 8 / 1000 / 1000).toFixed(2) : "0";
};

