import { ActionTypes } from "../../libs/enums";

const Reducer_GetAnnouncementCount = (state = 0, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_ANNOUNCEMENT_COUNT:
      return payload;
    default:
      return state;
  }
};

export default Reducer_GetAnnouncementCount;
