export const Get = async (url) => {
  const request = await fetch(`${url}`);
  const result = await request.json();

  return result;
};

export const Post = async (url = "", data = {}) => {
  const request = await fetch(`${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await request.json();

  return result;
};

export const PostArrayData = async (url = "", data) => {
  const request = await fetch(`${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  const result = await request.json();

  return result;
};
