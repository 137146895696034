import { combineReducers } from "@reduxjs/toolkit";
import Reducer_GetAnnouncementCount from "./getAnnouncementCount";
import Reducer_GetCustomServiceIsActive from "./getCustomServiceIsActive";
import { Reducer_Paging } from "./paging";
import Reducer_GetAnnouncementType from "./getAnnouncementType";
import Reducer_SearchModal from "./searchModal";

const RootReducer = combineReducers({
  getAnnouncementCount: Reducer_GetAnnouncementCount,
  getAnnouncementType: Reducer_GetAnnouncementType,
  paging: Reducer_Paging,
  getCustomServiceIsActive: Reducer_GetCustomServiceIsActive,
  searchModal: Reducer_SearchModal,
});

export default RootReducer;
