import useTranslation from "next-translate/useTranslation";
import { LanguageUid } from "../../../libs/enums";
import styles from "./styles.module.css";

const NewsNoData = () => {
  const { t } = useTranslation("GetLanguageResourceResponse");

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.title}>Opps!</h1> */}
      <h1 className={styles.title}>{t(LanguageUid.NO_DATA)}</h1>

      <div className={styles.iconContainer}>
        <i className="fa-solid fa-paperclip"></i>
        <i className="fa-solid fa-newspaper"></i>
        <i className="fa-solid fa-bullhorn"></i>
        <i className="fa-solid fa-note-sticky"></i>
        <i className="fa-solid fa-paper-plane"></i>
        <i className={`${styles.face} fa-solid fa-face-rolling-eyes`}></i>
      </div>
    </div>
  );
};

export default NewsNoData;
