import { ActionTypes } from "../../libs/enums";

const GetAction_CustomServiceIsActive = (isActive) => {
  return {
    type: ActionTypes.GET_CUSTOM_SERVICE_IS_ACTIVE,
    payload: isActive,
  };
};

const UpdateAction_CustomServiceIsActive = (isActive) => {
  return {
    type: ActionTypes.UPDATE_CUSTOM_SERVICE_IS_ACTIVE,
    payload: isActive,
  };
};

export { GetAction_CustomServiceIsActive, UpdateAction_CustomServiceIsActive };
