import { ActionTypes } from "../../libs/enums";

const Reducer_Paging = (
  state = { page: 1, showCount: 999999, start: 0, end: 999999 },
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.GET_PAGING:
      return payload;
    case ActionTypes.UPDATE_PAGING:
      return payload;
    default:
      return state;
  }
};

export { Reducer_Paging };
